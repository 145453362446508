*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Prompt', sans-serif;
  letter-spacing: 0.2px;
  // overflow-x: hidden !important;
  overflow-x: clip;
  @media (max-width: 475px) {
    overflow-x: unset;
  }
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffffff21;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00ff57;
  }
}
p,
h2,
ul {
  margin-bottom: 0;
}
a {
  outline: none !important;
  text-decoration: none !important;
  color: rgb(0, 2, 28, 0.95);
}
section {
  padding: 30px 0;
}

.container {
  max-width: 1200px !important;
  width: 100%;
  margin: 0 auto;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.privacy-detail {
  font-size: 16px;
  line-height: 2.5;
  font-weight: 300;
  h1 {
    margin: 0 auto;
    font-size: 30px;
  }
  b,
  h2 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
  }
  ul {
    margin-left: 50px;

    li {
      list-style-type: circle;
      display: list-item;
    }
  }
}

.sticky-cookie {
  position: sticky;
  bottom: 0;
  display: block;
  z-index: 101;
}

.img-scale-animate {
  overflow: hidden;

  &:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
    h3 {
      color: #cecece;
    }
  }
  img {
    -o-transition: all 1.5s ease-out;
    transition: all 1.5s ease-out;
    object-fit: cover;
  }
}

.resolution-image {
  position: relative;
  padding-top: 56.25% !important;
  overflow: hidden;
  display: block;
  img {
    position: absolute;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

.read-more {
  width: fit-content;
  font-size: 18px;
  font-weight: bold;
  white-space: pre;
  border-bottom: 2px solid #00ff57;
  transition: 1s ease-out 100ms;
  a {
    transition: 1s ease-out 100ms;
  }
  &:hover {
    a {
      color: #00ff57;
    }
    border-bottom: 2px solid #1e9245;
  }
}

.banner-special {
  width: 100%;

  img {
    max-width: 100%;
    width: 100%;
    margin-bottom: -7px;
  }
}

.hide-pwa {
  display: none !important;
}
.pwa {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 11;
  top: 10px;
  .class-pwa-popup {
    position: absolute;
    right: 10px;
    @media (max-width: 480px) {
      right: 50%;
      transform: translate(50%, 0);
    }
    .bg-pwa {
      background-color: rgba(0, 2, 28, 0.9);

      padding: 8px 10px;
      color: #fff;
      align-items: center;
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;

      img {
        width: 50px;
        padding: 0 5px;
        vertical-align: middle;
        margin-right: 10px;
      }
      p {
        margin: 0;
        font-size: 14px;
        white-space: pre;
        padding: 5px;
        // border-left: 2px solid #00ff57;
        &:nth-child(2) {
          padding: 0 5px 5px 5px;
          font-size: 12px;
        }
      }
      .add-button,
      .add-button-close {
        // border-left: 2px solid #00ff57;
        padding: 5px;
        text-align: center;
        white-space: pre;
        cursor: pointer;
      }

      .install-close {
        border-left: 1px solid #363636;
        padding-left: 10px;
        margin-left: 10px;
      }

      .add-button {
        background-color: #00ff57;
        font-weight: bold;
        font-size: 14px;
        border-radius: 3px;
        padding: 6px 8px;
        color: rgb(0, 2, 28, 0.95);
        margin-bottom: 5px;
        &:hover {
          // .add-button{
          color: #fff;
          background-color: rgb(66, 66, 66);
          // }
        }
      }

      .add-button-close {
        font-size: 12px;
        // border-top: 2px solid #00ff57;
        &:hover {
          color: #00ff57;
        }
      }
    }
  }
}

.animation-arrow {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-left {
  margin: 15px 20px 0 auto;

  width: 10px;
  height: 10px;
  transform: translateY(0px) translateX(0) rotate(-135deg);

  span {
    position: absolute;
    bottom: 10;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-left: 2px solid #00ff57;
    border-bottom: 2px solid #00ff57;
    animation: animate-left 1.5s linear infinite;
  }

  :nth-child(1) {
    transform: translate(6px, -6px);
    transition-delay: -0.4s;
  }
  :nth-child(2) {
    transform: translateX(0, 0);
    transition-delay: -0.2s;
  }
  :nth-child(3) {
    transform: translate(-6px, 6px);
    transition-delay: 0s;
  }
  @keyframes animate-left {
    0% {
      left: 5px;
      top: -5px;
      opacity: 0;
    }
    25% {
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    100% {
      left: -5px;
      top: 5px;
      opacity: 0;
    }
  }
}

.animation-right {
  width: 10px;
  height: 10px;
  margin: 15px auto 0 20px;
  // background-color: #262626;
  transform: translateY(0px) translateX(0) rotate(-135deg);

  span {
    position: absolute;
    bottom: 10;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-top: 2px solid #00ff57;
    border-right: 2px solid #00ff57;
    animation: animate-right 1.5s linear infinite;
  }

  :nth-child(1) {
    transform: translate(6px, -6px);
    transition-delay: -0.4s;
  }
  :nth-child(2) {
    transform: translateX(0, 0);
    transition-delay: -0.2s;
  }
  :nth-child(3) {
    transform: translate(-6px, 6px);
    transition-delay: 0s;
  }
  @keyframes animate-right {
    0% {
      left: -5px;
      top: 5px;
      opacity: 0;
    }
    25% {
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    100% {
      left: 5px;
      top: -5px;
      opacity: 0;
    }
  }
}

.error-content {
  text-align: center;
  margin-top: 50px;
  img {
    width: 100%;
    opacity: 0.6;
    max-width: 450px;
    @media (max-width: 480px) {
      max-width: 300px;
    }
  }
}

.text-search {
  font-family: 'Prompt', sans-serif;
}

.text-excerpt-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.text-excerpt-3 {
  @extend .text-excerpt-2;
  -webkit-line-clamp: 3;
}

.breadcrumb {
  display: flex;
  min-height: 40px;
  background: rgb(252, 252, 252);
  background: linear-gradient(90deg, rgb(248, 248, 248) 10%, rgba(255, 255, 255, 1) 100%);
  color: #777;
  margin: 30px 0px 15px 0;
  padding: 10px 10px 10px 15px;
  border-radius: 6px;
  font-size: 12px;
  li {
    &:hover {
      a {
        color: rgba(0, 170, 44);
      }
    }
  }

  li + li {
    &:before {
      content: '/';
      padding: 0 8px;
      color: #00c92b;
    }
  }
}

.heading {
  background-color: #00ff55;
  width: fit-content;
  padding: 0px 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.75;
  margin: 0 0 0 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  text-transform: uppercase;
  white-space: pre;
  border-radius: 2px;
  h2 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.75;
  }
}

.overflow-hidden {
  overflow: hidden;
}
.hide {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 1000em;
}

.fetching {
  font-size: 20px;
  width: fit-content;
  white-space: pre;
  border-bottom: 2px solid #00ff57;
  margin: 30px auto;
}

.show-landing-page {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.d-flex {
  display: flex;
}

.row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.2);
  margin-left: calc(var(--bs-gutter-x) * -0.2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.text-align-center {
  text-align: center;
}

.font-22 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.75;
  color: rgba(0, 2, 28, 0.95);
}

.w-100 {
  width: 100%;
  max-width: 100%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 767px) {
  .position-md-unset {
    position: unset !important;
  }
  .d-md-block {
    display: block !important;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .pe-md-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1023px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.m-0 {
  margin: 0;
}

.mb-30 {
  margin-bottom: 30px;
}
.mx-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.pt-20 {
  padding-top: 20px;
}

.p-30 {
  padding: 30px 0;
}

.pe-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.m-auto {
  margin: auto;
}
.mt-15 {
  margin-top: 15px;
}
.mt-30 {
  margin-top: 30px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.justify-center {
  justify-content: center;
}

// ads style

.ads-sticky-overflow {
  width: 100%;
  text-align: center;
  position: sticky;
  // bottom: 0;
}

#truehits_div {
  display: none;
  text-align: center;
  background: #010b2b;
  padding-bottom: 10px;
}

.ads,
.ads-inarticle {
  width: 100%;
  height: auto;
  margin: 15px auto !important;
  text-align: center;
  overflow-x: hidden;
  img,
  iframe {
    display: block;
    margin: auto;
  }
}

.ads-billboard,
.ads-Postartice,
.ads-sticky {
  // min-height: 100px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.default-sticky {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-billboard {
  margin: auto;
  min-height: 250px;
  @media (max-width: 475px) {
    min-height: 300px;
  }
  iframe {
    margin: auto;
  }
}

// section vdo  ,content , category
.playlist-custom-scroll {
  &.--vertical {
    overflow-x: hidden;
    height: 364px;
  }
  &.--horizontal {
    display: flex;
    overflow-x: scroll;
    width: auto;
    white-space: nowrap;
    max-width: 100%;
    ul {
      li {
        padding: 10px;
      }
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.background-default {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  max-width: 99vw;
  max-height: 99vh;
}

.back-drop {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 21.38px, rgba(0, 0, 0, 0.19) 0px 32px 64px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
}
